/* Base styles */
.hero-section {
  background-size: cover;
  background-position: center;
  height: 100vh;
  display: flex;
  align-items: center;
  position: relative;
}

.content-wrapper {
  background-color: rgba(255, 255, 255, 0.8);
  padding: 1.5rem;
  border-radius: 10px;
  margin: 0 15px;
  transition: all 0.3s ease;
}

.hero-title {
  font-size: calc(1.5rem + 1.5vw);
  line-height: 1.3;
}

.hero-subtitle {
  font-size: calc(0.9rem + 0.3vw);
}

/* Mobile adjustments */
@media (max-width: 768px) {
  .hero-section {
    align-items: flex-start;
  }

  .hero-container {
    margin-top: 20vh; /* Adjust this value to move content up/down */
  }

  .content-wrapper {
    padding: 1rem;
    margin: 0 10px;
  }

  .hero-title {
    font-size: calc(1.2rem + 1vw);
  }

  .hero-subtitle {
    font-size: 0.9rem;
  }
}

/* Small mobile adjustments */
@media (max-width: 480px) {
  .hero-container {
    margin-top: 15vh; /* Even higher on smaller screens */
  }
}

/* Add to your existing CSS */

.quote-wrapper {
  position: absolute;
  bottom: 2rem;
  left: 2rem;
  max-width: 400px;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 1.5rem;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.quote-text {
  font-style: italic;
  font-size: 1.1rem;
  margin-bottom: 0.5rem;
  color: #2f2f2f;
  line-height: 1.4;
}

.quote-author {
  font-size: 0.9rem;
  color: #666;
  margin-bottom: 0;
}

/* Update mobile adjustments */
@media (max-width: 768px) {
  .quote-wrapper {
    bottom: 1rem;
    left: 1rem;
    right: 1rem;
    padding: 1rem;
    max-width: none;
  }

  .quote-text {
    font-size: 1rem;
  }

  .quote-author {
    font-size: 0.8rem;
  }
} 