.service-hero {
  height: 50vh;
  position: relative;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
}

.service-hero::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.85);
}

.service-content {
  margin-top: -50px;
  position: relative;
  z-index: 2;
}

.service-image {
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.service-image:hover {
  transform: translateY(-5px);
}

.service-image img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.service-details {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
}

.service-details h3 {
  color: #2f55d4;
  margin-top: 2rem;
  margin-bottom: 1rem;
  font-size: 1.5rem;
}

.service-details p {
  color: #4f5665;
  line-height: 1.8;
  margin-bottom: 1.5rem;
}

.service-details .btn {
  padding: 0.75rem 1.5rem;
  transition: all 0.3s ease;
}

/* Mobile Responsiveness */
@media (max-width: 768px) {
  .service-hero {
    height: 40vh;
  }

  .service-content {
    padding: 2rem 0;
    margin-top: -30px;
  }

  .service-details {
    padding: 1.5rem !important;
  }

  .service-image {
    margin-bottom: 2rem;
  }
}

/* Animation for content */
.fade-in {
  animation: fadeInUp 0.6s ease-out;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
} 