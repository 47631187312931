.section {
  padding-top: 80px !important; /* Adjust this value based on your header height */
}

@media (max-width: 768px) {
  .section {
    padding-top: 70px !important;
  }
  
  #topnav {
    height: 70px;
  }
} 

/* Add padding for fixed header */
body {
    padding-top: 10px;
}

/* Adjust section padding for different screen sizes */
@media (max-width: 768px) {
    body {
        padding-top: 10px;
    }
    
    .section {
        padding-top: 10px;
    }
}

/* Ensure navbar stays on top */
#topnav {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
} 

html {
  scroll-behavior: smooth;
  /* Add default scroll padding to account for fixed header */
  scroll-padding-top: 80px;
}

.service-detail-section {
  padding: 80px 0;
  min-height: 100vh;
}

.nav-social-link {
  display: flex;
  align-items: center;
  padding: 0 15px;
  color: #3c4858;
}

.nav-social-link:hover {
  color: #2f55d4;
}