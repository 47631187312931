// Custom color variables
$primary-color: #7C9885;
$secondary-color: #F5E6E8;
$accent-color: #D8A7B1;
$text-color: #454545;
$light-bg: #F9F7F7;

// Custom fonts
@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@400;500;600;700&family=Montserrat:wght@300;400;500;600&display=swap');

// Base styles
body {
  font-family: 'Montserrat', sans-serif;
  color: $text-color;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Cormorant Garamond', serif;
}

// Section backgrounds
.section {
  &.bg-light {
    background-color: $light-bg;
  }
  
  &.bg-pattern {
    background-image: url('../images/pattern-bg.png');
    background-repeat: repeat;
    background-size: 200px;
    background-opacity: 0.1;
  }
}

// Card styles
.card {
  border-radius: 15px;
  transition: transform 0.3s ease;
  
  &:hover {
    transform: translateY(-5px);
  }
}

// Button styles
.btn {
  border-radius: 30px;
  padding: 12px 30px;
  font-weight: 500;
  
  &-primary {
    background-color: $primary-color;
    border-color: $primary-color;
    
    &:hover {
      background-color: darken($primary-color, 10%);
      border-color: darken($primary-color, 10%);
    }
  }
}

// Navigation styles
#topnav {
  background-color: rgba(255, 255, 255, 0.95);
  
  .navigation-menu > li > a {
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
  }
}

.course-feature {
  display: flex;
  flex-direction: column;
  
  .card-body {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  
  .text-primary {
    margin-top: auto;
  }
}

// Contact page styles
.form-icon {
  .form-control {
    padding-left: 50px !important;
  }
  
  i {
    z-index: 1;
    color: $primary-color;
  }
}

.contact-detail {
  .icon {
    width: 45px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: rgba($primary-color, 0.1);
    
    i {
      color: $primary-color;
    }
  }
}

.shape {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
}
