body {
    font-family: 'Raleway', sans-serif;
  }
  
  h1, h2, h3, h4, h5, h6,
  .hero-title,
  .hero-subtitle,
  .nav-link,
  .btn,
  .section-title,
  .quote-text,
  .quote-author {
    font-family: 'Raleway', sans-serif !important;
  }