.text-primary,
.btn-soft-primary,
.icons.text-primary,
.uil.text-primary,
.h5.text-primary,
.badge.bg-soft-primary,
.social-icon li a:hover .fea-social,
p.text-primary,
span.text-primary,
h6.text-primary,
a.text-primary,
i.text-primary,
.text-primary.para-desc,
.title-heading .text-primary {
    color: #000000 !important;
}

.btn-primary {
    background-color: #000000 !important;
    border-color: #000000 !important;
}

.btn-primary:hover, 
.btn-primary:focus, 
.btn-primary:active, 
.btn-primary.active, 
.btn-primary.focus {
    background-color: #333333 !important;
    border-color: #333333 !important;
}

.btn-outline-primary {
    color: #000000 !important;
    border-color: #000000 !important;
}

.btn-outline-primary:hover {
    background-color: #000000 !important;
    color: #ffffff !important;
}

.bg-soft-primary {
    background-color: rgba(0, 0, 0, 0.1) !important;
}

h3.text-primary,
.service-details h3 {
    color: #000000 !important;
} 